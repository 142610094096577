<script lang="ts" setup>
import { computed, ref } from 'vue';
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue';
import DesktopIcon from './icons/DesktopIcon.vue';
import TabletIcon from './icons/TabletIcon.vue';
import MobileIcon from './icons/MobileIcon.vue';
import { useElementBounding } from '@vueuse/core';

const listDevices = {
  desktop: DesktopIcon,
  tablet: TabletIcon,
  mobile: MobileIcon,
};

type ScreenType = keyof typeof listDevices;

type Props = {
  controlType?: string;
  currentScreen?: ScreenType;
  menuClass?: string;
};

const props = withDefaults(defineProps<Props>(), {
  currentScreen: 'desktop',
});

const emit = defineEmits<{
  (e: 'changeScreen', screenId: ScreenType): void;
}>();

// Methods
const controlHasDevices = computed(() => {
  const typeInvisible = ['visibility'];
  return !typeInvisible.includes(props.controlType || '');
});
const devices: ScreenType[] = ['desktop', 'tablet', 'mobile'];

const setActiveScreenId = (screenId: ScreenType) => {
  emit('changeScreen', screenId);
};

const menuButton = ref<HTMLElement>();
let isMenuItemBottom = false;

const onClick = () => {
  const { bottom } = useElementBounding(menuButton);
  if (window.innerHeight - bottom.value < 105) {
    isMenuItemBottom = true;
  }
};
</script>

<template>
  <div class="flex items-center">
    <Menu
      v-if="controlHasDevices"
      :class="menuClass"
      as="div"
      class="hover:rounded-medium relative ml-[9px] flex items-center">
      <MenuButton
        class="text-dark-low hover:rounded-medium inline-flex h-24 w-24 items-center justify-center bg-opacity-20 font-medium transition duration-200 hover:bg-[#3B3B3B] focus:outline-none focus-visible:ring-2 focus-visible:ring-white">
        <component :is="listDevices[currentScreen]" class="text-dark-disabled" width="20" height="20"></component>
      </MenuButton>
      <MenuItems
        class="bg-light-high shadow-4dp rounded-12 absolute right-[-12px] z-[100] flex origin-top-right flex-col gap-4 p-4 focus:outline-none"
        :class="[isMenuItemBottom ? 'bottom-16' : 'top-24']">
        <MenuItem v-for="device in devices" :key="device">
          <button
            class="group flex h-[32px] w-[32px] items-center justify-center rounded-xl transition-all duration-200"
            :class="{
              'text-dark-high !bg-dark-hover cursor-default': device == currentScreen,
              'text-dark-low hover:bg-dark-hover cursor-pointer': device != currentScreen,
            }"
            @click="setActiveScreenId(device)">
            <component :is="listDevices[device]" width="20" height="20"></component>
          </button>
        </MenuItem>
      </MenuItems>
    </Menu>
  </div>
</template>
