<template>
  <svg width="88" height="48" viewBox="0 0 88 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="43" height="48" rx="3" fill="#5E5E5E" />
    <path
      d="M14 18.766L18.995 17.0288C18.995 17.0288 19.6024 18.4454 21.505 18.4454C23.4076 18.4454 24.0083 17 24.0083 17L29 18.766L28.1692 23.1222H25.6642V31H17.325V23.1196H14.8325L14 18.766Z"
      fill="#F9F9F9" />
    <rect x="47" width="41" height="7" rx="3" fill="#5E5E5E" />
    <rect x="47" y="11" width="24" height="7" rx="3" fill="#5E5E5E" />
  </svg>
</template>
