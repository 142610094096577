<template>
  <span data-test="editor-header-control-page-status" class="ml-8 cursor-default">
    <g-badge
      v-if="status == 'draft'"
      icon="dot-primary"
      status="Draft"
      :last-action="lastAction"
      class-color="text-dark-low"
      class-container="bg-dark-300"></g-badge>
    <g-badge
      v-else-if="status === 'needRepublish'"
      icon="dot-primary"
      status="Need republishing"
      :last-action="lastAction"
      class-color="text-amber-300"
      class-container="bg-[#FDB91333]"></g-badge>
    <g-badge
      v-else
      icon="dot-primary"
      status="Published"
      :last-action="lastAction"
      class-color="text-green-200"
      class-container="bg-[#00C85333] "></g-badge>
  </span>
</template>
<script lang="ts" setup>
import { toRefs } from 'vue';
import GBadge from '../../base/GBadge.vue';
import { lastUpdateAt } from '@/utils/function/common';

type Props = {
  status?: 'needRepublish' | 'published' | 'draft';
  lastUpdatedAt?: string;
};
const props = defineProps<Props>();
const { lastUpdatedAt, status } = toRefs(props);
const lastAction = computed(() => {
  if (status?.value === 'published') {
    return `Published at ${lastUpdateAt(lastUpdatedAt?.value || new Date().toString())}`;
  }
  return `Last modified ${lastUpdateAt(lastUpdatedAt?.value || new Date().toString())}`;
});
</script>
