<script lang="ts" setup>
import { computed } from 'vue';
import type { ButtonType } from '../types/button';
import { cn } from '../helpers/common';

const emit = defineEmits(['click']);
const props = withDefaults(
  defineProps<{
    buttonType?: 'button' | 'submit';
    type?: ButtonType;
    size?: 'large' | 'medium' | 'small';
    loading?: boolean;
    disable?: boolean;
    onlyIcon?: boolean;
    buttonClasses?: string;
    class?: any;
    hideAttributeDisabled?: boolean;
    active?: boolean;
    loadingClass?: string;
    loadingIcon?: string;
  }>(),
  {
    buttonType: 'button',
    type: 'primary',
    size: 'large',
  },
);

//=================== Style ======================
const computedClass = computed(() => {
  const classes = [
    'flex',
    'whitespace-nowrap',
    'items-center',
    'justify-center',
    'font-medium',
    'rounded-xl',
    'transition-colors',
    'duration-200',
    'overflow-hidden',
  ];
  if (props.loading) {
    classes.push('cursor-default');
  } else if (props.disable) {
    classes.push('!cursor-not-allowed');
  } else {
    classes.push('cursor-pointer');
  }
  switch (props.type) {
    case 'moreSetting':
      if (props.loading) {
        classes.push('bg-dark-400 border border-dark-400 text-dark-high');
      } else if (props.disable) {
        classes.push('bg-dark-400 border border-dark-400  text-dark-disabled');
      } else if (props.active) {
        classes.push('bg-primary-300 border border-primary-300 text-light-100');
      } else {
        classes.push('bg-dark-400 border border-dark-400 text-dark-high hover:bg-dark-100');
      }
      break;
    case 'primary':
      if (props.loading) {
        classes.push('bg-primary-300 border border-primary-300 text-light-100');
      } else if (props.disable) {
        classes.push('bg-light-300 border border-light-300 text-light-disabled');
      } else {
        classes.push('bg-primary-300 border border-primary-300 text-[#F9F9F9] hover:bg-primary-325 ');
      }
      break;
    case 'secondary':
      if (props.loading) {
        classes.push(
          'dark:bg-dark-300 border border-dark-300 dark:text-light-100 bg-light-300 bg-light-300 text-light-high hover:bg-dark-high',
        );
      } else if (props.disable) {
        classes.push('dark:bg-dark-300 border border-dark-300 dark:text-dark-disabled bg-light-300');
      } else if (props.active) {
        classes.push(
          'dark:bg-dark-200 border border-dark-200 dark:text-light-100 dark:hover:bg-dark-200 bg-light-300 text-light-high hover:bg-dark-high',
        );
      } else {
        classes.push('bg-dark-300 text-dark-high border border-dark-300 hover:bg-dark-200 hover:border-dark-200');
      }
      break;

    case 'darkBlue':
      if (props.loading) {
        classes.push(
          'dark:bg-primary-300/15 border border-primary-300/0 dark:text-light-100 bg-light-300 bg-light-300 text-light-high hover:bg-dark-high',
        );
      } else if (props.disable) {
        classes.push('dark:bg-primary-300/15 border border-primary-300/0 dark:text-dark-disabled bg-light-300');
      } else if (props.active) {
        classes.push(
          'dark:bg-dark-200 border border-dark-200 dark:text-light-100 dark:hover:bg-dark-200 bg-light-300 text-light-high hover:bg-dark-high',
        );
      } else {
        classes.push(
          'bg-primary-300/15 text-primary-250 border border-primary-300/0 hover:bg-dark-200 hover:border-dark-200',
        );
      }
      break;

    case 'tertiary':
      if (props.loading) {
        classes.push(
          'relative before:-z-0 rounded-xl text-dark-high hover:before:bg-light-100/20 bg-dark-300 before:h-full before:w-full before:absolute before:top-0 before:left-0 before:z-[-1] before:rounded-xl',
        );
      } else if (props.disable) {
        classes.push('rounded-xl text-[#757575] bg-dark-300');
      } else {
        classes.push(
          'relative before:z-0 rounded-xl text-dark-high hover:bg-[#4A4A4A] bg-dark-300 before:h-full before:w-full before:absolute before:top-0 before:left-0 before:z-[-1] before:rounded-xl',
        );
      }
      break;
    case 'amber':
      if (props.loading) {
        classes.push(
          'relative before:!z-0 rounded-xl text-light-high hover:before:bg-light-100/20 bg-amber-300 before:h-full before:w-full before:absolute before:top-0 before:left-0 before:z-[-1] before:rounded-xl',
        );
      } else if (props.disable) {
        classes.push('rounded-xl text-light-disabled');
      } else {
        classes.push(
          'relative before:!z-0 rounded-xl text-light-high hover:before:bg-light-100/20 bg-amber-300 before:h-full before:w-full before:absolute before:top-0 before:left-0 before:z-[-1] before:rounded-xl',
        );
      }
      break;
    case 'darkGhost':
      if (props.loading) {
        classes.push(
          'relative before:-z-0 rounded-xl text-dark-high hover:before:bg-light-100/20 bg-dark-500 before:h-full before:w-full before:absolute before:top-0 before:left-0 before:z-[-1] before:rounded-xl',
        );
      } else if (props.disable) {
        classes.push('rounded-xl text-light-disabled');
      } else {
        classes.push(
          'relative before:z-0 rounded-xl text-dark-high hover:before:bg-light-100/20 bg-dark-500 before:h-full before:w-full before:absolute before:top-0 before:left-0 before:rounded-xl',
        );
      }
      break;
    case 'grey':
      if (props.loading) {
        classes.push('bg-light-300 border border-light-300 text-light-high');
      } else if (props.disable) {
        classes.push('bg-light-300 border border-light-300 text-light-disabled');
      } else {
        classes.push('bg-light-300 border border-light-300 text-light-high hover:bg-dark-high');
      }
      break;
    case 'outline':
      if (props.loading) {
        classes.push('border border-dark-100 bg-transparent text-light-high');
      } else if (props.disable) {
        classes.push('border border-dark-100 bg-transparent text-light-disabled');
      } else {
        classes.push('border border-dark-100 bg-transparent text-light-high hover:bg-dark-high');
      }
      break;
    case 'invert':
      if (props.loading) {
        classes.push(
          ' dark:bg-dark-400 dark:hover:bg-light-100  dark:hover:bg-opacity-20 hover:bg-dark-high  dark:text-dark-high bg-light-100 border border-light-100  text-light-high',
        );
      } else if (props.disable) {
        classes.push(
          'dark:bg-dark-400 border border-light-100  bg-light-100 dark:text-dark-disabled text-light-disabled',
        );
      } else {
        classes.push(
          'dark:bg-dark-400 dark:text-dark-high dark:hover:bg-light-100  dark:hover:bg-opacity-20  hover:bg-dark-high bg-light-100 border border-light-100 text-light-high',
        );
      }
      break;
    case 'ghost':
      if (props.loading) {
        classes.push('bg-transparent border border-transparent text-dark-high ');
      } else if (props.disable) {
        classes.push('border border-transparent hover:bg-transparent hover:border-transparent text-dark-disabled');
      } else if (props.active) {
        classes.push('dark:bg-dark-hover bg-transparent border border-transparent text-dark-disabled');
      } else {
        classes.push(
          'bg-transparent border border-transparent text-dark-high hover:bg-black hover:bg-opacity-20 group-hover/button:bg-[#E2E2E2]  dark:hover:!bg-dark-hover',
        );
      }
      break;
    case 'danger':
      if (props.loading) {
        classes.push(
          'bg-red-300 relative before:z-0 text-light-100 before:h-full before:w-full before:absolute before:top-0 before:left-0 before:z-[-1] text-light-100 hover:before:bg-light-100/20',
        );
      } else if (props.disable) {
        classes.push('bg-light-high border border-light-high text-dark-disabled');
      } else {
        classes.push(
          'relative before:z-0 bg-red-300 before:h-full before:w-full before:absolute before:top-0 before:left-0 before:z-[-1] text-light-100 hover:before:bg-light-100/20 hover:text-dark-high',
        );
      }
      break;
    case 'dangerGhost':
      if (props.loading) {
        classes.push('bg-transparent border border-transparent text-red-200');
      } else if (props.disable) {
        classes.push('bg-transparent border border-transparent text-dark-disabled');
      } else {
        classes.push('bg-transparent border border-transparent text-red-200 hover:bg-red-300/20');
      }
      break;
  }
  switch (props.size) {
    case 'large':
      classes.push('h-40 text-14 leading-normal');

      if (!props.onlyIcon) {
        classes.push('px-16');
      }
      break;
    case 'medium':
      classes.push('h-36 text-14 leading-normal');

      if (!props.onlyIcon) {
        classes.push('px-16');
      }
      break;
    case 'small':
      classes.push('h-24 text-12 leading-normal');

      if (!props.onlyIcon) {
        classes.push('px-16');
      }
      break;
  }
  if (props.onlyIcon) {
    classes.push('aspect-square');
  }
  return classes.join(' ');
});

//=================== Methods =====================
const handleClick = (e: Event) => {
  if (props.loading) {
    return;
  }
  emit('click', e);
};
</script>
<template>
  <button
    :data-type="type"
    :type="buttonType"
    :class="
      cn(
        'cursor-pointer select-none outline-none disabled:cursor-not-allowed',
        computedClass,
        buttonClasses,
        props.class,
      )
    "
    data-test="editor-control-g-button"
    :disabled="disable && !hideAttributeDisabled"
    @click="handleClick">
    <span
      v-if="loading"
      :class="
        cn(
          {
            'mr-8': !onlyIcon,
            'w-16': size == 'large',
            'w-14': size == 'medium',
            'w-12': size == 'small',
          },
          loadingClass,
        )
      ">
      <g-base-icon
        class="animate-spin ease-in-out"
        :name="loadingIcon ? loadingIcon : 'loading'"
        width="16"
        height="16"></g-base-icon>
    </span>
    <template v-if="!loading || !onlyIcon">
      <slot name="prefix"></slot>
      <slot></slot>
      <slot name="suffix"></slot>
    </template>
  </button>
</template>
