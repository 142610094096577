<script lang="ts" setup>
import { arrow, arrowPlacement, useFloating } from '../composables/useFloating';
import type { Placement, Strategy } from '@floating-ui/core';
import { autoPlacement, offset, shift } from '@floating-ui/core';
import { computed, ref, Teleport, watch } from 'vue';
import { cn } from '../helpers/common';

const props = withDefaults(
  defineProps<{
    strategy?: Strategy;
    trigger?: 'click' | 'hover';
    hasArrow?: boolean;
    disabled?: boolean;
    placement?: Placement;
    wrapperClass?: string;
    contentClass?: string;
    arrowClass?: string;
    contentStyles?: Record<string, any>;
    arrowStyles?: Record<string, any>;
    duration?: number;
    marginLeft?: number;
    marginTop?: number;
    forceClose?: boolean;
    isTeleport?: boolean;
  }>(),
  {
    hasArrow: true,
    strategy: 'absolute',
    trigger: 'hover',
    duration: 0,
    marginLeft: 0,
    marginTop: 0,
  },
);

const emits = defineEmits<{
  (e: 'close-auto-open'): void;
}>();

const isOpen = ref(false);
const arrowEl = ref<HTMLElement>();
const timeout = ref<any>(null);
let timeoutAutoOpen: ReturnType<typeof setTimeout>;

const componentName = computed(() => (props.isTeleport ? Teleport : 'div'));

const { x, y, reference, floating, strategy, middlewareData } = useFloating({
  strategy: props.strategy,
  placement: props.placement,
  middleware: [
    autoPlacement({
      ...(props.placement ? { allowedPlacements: [props.placement] } : {}),
    }),
    shift(),
    offset(8),
    arrow({
      element: arrowEl,
    }),
    arrowPlacement(),
  ],
});

function toggleOpen() {
  if (props.trigger !== 'click') return;
  isOpen.value = !isOpen.value;
}
function onMouseOver() {
  if (props.trigger === 'click') return;
  if (!isOpen.value) {
    timeout.value = setTimeout(() => {
      isOpen.value = true;
    }, props.duration);
  }
}
function onMouseOut() {
  if (timeout.value) clearTimeout(timeout.value);
  if (props.trigger === 'click') return;
  if (isOpen.value) {
    isOpen.value = false;
  }
}
const placement = computed<Placement>(() => middlewareData.value?.arrowPlacement?.placement);

const autoOpen = () => {
  isOpen.value = true;
  clearTimeout(timeoutAutoOpen);
  timeoutAutoOpen = setTimeout(() => {
    isOpen.value = false;
    emits('close-auto-open');
  }, 2000);
};

defineExpose({
  autoOpen,
});

watch(
  () => props.forceClose,
  (value) => {
    if (value) {
      isOpen.value = false;
    }
  },
);
</script>

<template>
  <div
    ref="reference"
    class="inline-flex"
    :class="[$attrs.class, wrapperClass]"
    @click.prevent="toggleOpen"
    @mouseover="onMouseOver"
    @mouseleave="onMouseOut">
    <slot></slot>
  </div>

  <component :is="componentName" to="body">
    <Transition name="tooltip">
      <div
        v-if="!disabled && isOpen"
        ref="floating"
        class="gemx-tooltip shadow-4dp pointer-events-none z-50"
        :style="{
          position: strategy,
          top: y ? `${y - marginTop}px` : '',
          left: x ? `${x - marginLeft}px` : '0px',
          ...(contentStyles || {}),
        }">
        <div :class="cn('bg-dark-300 text-dark-highV2 relative flex whitespace-nowrap rounded-xl p-8', contentClass)">
          <div
            v-if="hasArrow"
            ref="arrowEl"
            class="border-r-dark-300 absolute z-50 border-y-8 border-r-8 border-l-0 border-solid border-y-transparent"
            :style="{
              top: middlewareData.arrow?.y != null ? `${middlewareData.arrow.y}px` : '',
              left: middlewareData.arrow?.x != null ? `${middlewareData.arrow.x}px` : '',
              ...(arrowStyles || {}),
            }"
            :class="[
              {
                '-right-8 rotate-180': placement?.startsWith('left'),
                '-left-8': placement?.startsWith('right'),
                '-top-8 rotate-90': placement?.startsWith('bottom'),
                '-bottom-8 -rotate-90': placement?.startsWith('top'),
              },
              arrowClass,
            ]"></div>
          <div class="text-12">
            <slot name="content" v-bind="{ toggleOpen }"></slot>
          </div>
        </div>
      </div>
    </Transition>
  </component>
</template>

<style scoped></style>
