<template>
  <div class="gemx-control gemx-control-toggle group/toggle">
    <div class="gemx-toggle-bound flex items-center [&>.gemx-control-label]:mb-0">
      <slot name="label"></slot>
      <div class="gemx-toggle_switch select-none" @click="toggle">
        <div
          class="gemx-toggle_switch_line bg-light-low dark:bg-light-disabled group-hover/toggle:bg-[#AAAAAA]"
          :class="{
            active: value,
            disable: readonly,
            'value-true !bg-primary-300 dark:!bg-primary-275 group-hover/toggle:!bg-[#7190FF]': value,
          }" />
        <span class="gemx-toggle_switch_slider" :class="{ active: value, disable: readonly, 'value-true': value }" />
      </div>
    </div>
    <slot name="info"></slot>
    <slot name="modal"></slot>
  </div>
</template>

<script lang="ts" setup>
type PropsType = {
  id?: string;
  value?: boolean;
  readonly?: boolean;
};
const props = withDefaults(defineProps<PropsType>(), {
  readonly: false,
});
const emit = defineEmits<{
  (e: 'onChange', controlId?: string, value?: any): void;
  (e: 'change', controlId?: string, value?: any): void;
}>();

const toggle = () => {
  if (props.readonly) return;
  change();
};
const change = () => {
  emit('change', props.id, !props.value);
};
</script>

<style lang="postcss" scoped>
.gemx-control-toggle {
  .gemx-toggle-bound {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;

    :deep(.control-label) {
      margin: 0;
      padding-right: 5px;
      width: calc(100% - 55px);
      align-items: center;
    }
    .gemx-toggle_switch {
      position: relative;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      margin-left: auto;
      cursor: pointer;
      --width: 32px;
      width: var(--width);

      input {
        opacity: 0;
        position: absolute;
        top: calc(0% - 40% / 2);
        bottom: calc(0% - 40% / 2);
        left: calc(0% - 40% / 2);
        right: calc(0% - 40% / 2);
        width: calc(100% + 40%);
        z-index: 2;
        height: calc(100% + 40%);
        display: flex;
        box-sizing: border-box;
        cursor: pointer;
      }
      .gemx-toggle_switch_line {
        position: absolute;
        width: 32px;
        --height: 16px;
        height: var(--height);
        top: calc(50% - var(--height) / 2);
        border-radius: 20px;
      }
      .gemx-toggle_switch_slider {
        --size: 14px;
        width: var(--size);
        height: var(--size);

        top: calc(50% - var(--size) / 2);
        left: 0;
        border-radius: 50%;
        -webkit-transition: all 0.2s linear;
        transition: all 0.2s linear;
        -webkit-transform: translateX(1px);
        transform: translateX(1px);
        background-color: #ffffff;

        &.active {
          -webkit-transform: translateX(calc(var(--width) - var(--size) - 1px));
          transform: translateX(calc(var(--width) - var(--size) - 1px));
        }
        &.disable {
          background-color: #494949;
          &.value-true {
            cursor: not-allowed;
            background-color: #0e3c82;
          }
        }
      }
    }
  }
}
</style>
