<template>
  <svg width="96" height="56" viewBox="0 0 96 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="28" width="40" height="36" rx="3" fill="#424242" />
    <path
      d="M40.5 12.266L45.495 10.5288C45.495 10.5288 46.1024 11.9454 48.005 11.9454C49.9076 11.9454 50.5083 10.5 50.5083 10.5L55.5 12.266L54.6692 16.6222H52.1642V24.5H43.825V16.6196H41.3325L40.5 12.266Z"
      fill="#D6D6D6" />
    <rect x="31" y="39" width="34" height="7" rx="3" fill="#424242" />
    <rect x="36" y="49" width="24" height="7" rx="3" fill="#424242" />
  </svg>
</template>
