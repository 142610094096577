<script lang="ts" setup>
import { TransitionRoot, TransitionChild, Dialog } from '@headlessui/vue';
import { ref } from 'vue';
import GBaseIcon from './GBaseIcon.vue';
import type { ModalProps } from '../types/common';
import GButton from './GButton.vue';
import { useDraggable, onClickOutside } from '@vueuse/core';
import { cn } from '../helpers/common';
import { GTooltip } from '..';

const emits = defineEmits(['ok', 'cancel', 'close', 'refresh', 'outside']);

const props = withDefaults(defineProps<ModalProps>(), {
  type: 'normal',
  btnCancelType: 'grey',
  btnOkType: 'primary',
  modelValue: false,
  labelCancel: 'Cancel',
  labelOk: 'Save',
  showBtnClose: false,
  showBtnRefresh: false,
  isDraggable: false,
  showLineTop: true,
  showLineBottom: true,
  placementCloseTooltip: 'left',
});

const focusRef = ref(null);
const target = ref(null);
const modalContainer = ref<HTMLElement>();
onClickOutside(target, () => emits('outside'));

const { style } = useDraggable(modalContainer, {
  initialValue: { x: 280, y: 50 },
  // preventDefault: true,
});

const onClose = () => {
  props.clickOutSideClose && emits('close');
};
</script>

<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog
      as="div"
      :class="cn('inset-0 z-50 flex items-center justify-center', { fixed: !contentFixed }, dialogClass)"
      :initial-focus="focusRef"
      @close="onClose">
      <div v-if="!hideOverlay" class="fixed inset-0 bg-black opacity-60" aria-hidden="true" @click="onClose" />
      <div
        ref="target"
        :class="cn('fixed  z-50 max-h-[90%] max-w-[90%]', containerClass)"
        :style="[isDraggable ? style : {}]">
        <component
          :is="!hiddenTransition ? TransitionChild : 'div'"
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          class="rounded-xxl h-full bg-white"
          leave-to="opacity-0">
          <div
            data-test="editor-control-setting-g-modal"
            :class="
              cn(
                '!shadow-6dp rounded-xxl flex h-full w-full min-w-[400px] transform flex-col overflow-hidden text-left align-middle transition-all',
                {
                  'shadow-modal': hideOverlay,
                  'w-[400px]': type === 'confirm',
                },
                modalClass,
              )
            ">
            <div
              v-if="!hideHeader"
              ref="modalContainer"
              data-test="editor-control-modal-title"
              :class="
                cn(
                  'bg-light-100 text-light-high relative z-20 flex items-center',
                  {
                    'cursor-move': isDraggable,
                  },
                  headerClass,
                )
              ">
              <div
                class="text-16 relative flex !h-[52px] w-full items-center justify-between truncate pl-16 font-semibold leading-6 tracking-[-0.32px] text-[#212121]"
                :class="headerTitleClass">
                <slot name="title"></slot>
              </div>
              <button
                v-if="showBtnRefresh"
                tabindex="-1"
                class="group/button flex h-[52px] w-[52px] flex-shrink-0 cursor-pointer items-center justify-center"
                :class="closeBtnClass"
                @click="$emit('refresh')">
                <g-button type="ghost" tabindex="-1" :only-icon="true" size="medium" :class="cn('h-32 w-32')">
                  <g-base-icon
                    v-if="refreshing"
                    data-test="editor-control-modal-button-refresh"
                    class="flex-shrink-0 !text-[#676767]"
                    :class="{ 'animate-spin': refreshing }"
                    name="small-loading"
                    width="16"
                    height="16"
                    color="#676767" />
                  <g-base-icon v-else name="refresh" width="16" height="16" view-box="0 0 16 16" />
                </g-button>
              </button>

              <template v-if="showBtnClose">
                <g-tooltip v-if="closeTooltipContent" :placement="placementCloseTooltip" class="flex py-0 pl-0">
                  <g-button
                    type="ghost"
                    tabindex="-1"
                    :only-icon="true"
                    size="small"
                    data-test="editor-control-modal-button-close"
                    class="text-[#676767]"
                    :class="closeBtnClass"
                    @click="$emit('close')">
                    <g-base-icon name="close" width="16px" height="16px" />
                  </g-button>

                  <template #content>
                    <span class="text-12 flex items-center">{{ closeTooltipContent || 'Cancel' }}</span>
                  </template>
                </g-tooltip>
                <div
                  class="group/button flex h-[52px] w-[52px] flex-shrink-0 cursor-pointer items-center justify-center"
                  @click="$emit('close')">
                  <g-button
                    type="ghost"
                    tabindex="-1"
                    :only-icon="true"
                    size="medium"
                    data-test="editor-control-modal-button-close"
                    :class="cn('h-32 w-32 text-[#676767]', closeBtnClass)">
                    <g-base-icon name="close" width="16px" height="16px" />
                  </g-button>
                </div>
              </template>
            </div>
            <!-- <hr v-if="showHr" :class="hrClass" /> -->
            <div
              data-test="editor-control-modal-body"
              :class="cn('relative z-10 flex-grow overflow-y-auto bg-white ', bodyClass)">
              <slot></slot>
            </div>
            <!-- <hr v-if="showHr" :class="hrClass" /> -->
            <div
              v-if="!hideActions"
              :class="cn('relative flex !h-[64px] flex-shrink-0 items-center justify-end bg-white px-16', footerClass)">
              <slot name="footer"></slot>

              <g-button
                v-if="!hideBtnCannel"
                data-test="model-btn-cancel"
                button-type="button"
                :type="btnCancelType"
                :class="
                  cn('text-light-high border !bg-[#F4F4F4] hover:!bg-[#E2E2E2] active:!bg-[#E2E2E2]', cancelBtnClass)
                "
                @click="$emit('cancel')"
                >{{ labelCancel }}</g-button
              >

              <g-button
                ref="focusRef"
                data-test="model-btn-ok"
                button-type="button"
                :class="cn('ml-8 leading-6', okBtnClass)"
                :type="btnOkType"
                :disable="btnOkDisable"
                :loading="loading"
                :only-icon="okBtnOnlyIcon"
                @click="!btnOkDisable && $emit('ok')">
                {{ labelOk }}
              </g-button>
            </div>
          </div>
        </component>
        <div
          v-if="!hideHeader && showLineTop"
          class="show-Line absolute top-[52px] left-0 h-[1px] w-[100%] bg-[#EEEEEE]"
          :class="showLineCustom"></div>
        <div
          v-if="!hideActions && showLineBottom"
          class="show-Line absolute bottom-[64px] left-0 h-[1px] w-full bg-[#EEEEEE]"
          :class="showLineCustom"></div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
