<script lang="ts" setup>
import { computed, watch } from 'vue';
import { ref } from 'vue';
import useFlip from '../composables/useFlip';
import { useOutsideClick } from '../composables/useOutsideClick';

type Props = {
  trigger?: 'hover' | 'click';
  placement?: 'left' | 'right' | '';
  classButton?: string;
  classWrapper?: string;
  classPopover?: string;
  ignoreClickOutsideSelectors?: string[];
  isOpen?: boolean;
  disable?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  trigger: 'hover',
  placement: '',
  classButton: '',
  classWrapper: '',
  classPopover: '',
});

const emit = defineEmits<{
  (e: 'open'): void;
  (e: 'close'): void;
  (e: 'outside-click'): void;
}>();

const open = ref(props.isOpen);
const timeout = ref();
const content = ref<HTMLElement>();
const dropdown = ref<HTMLElement>();
const buttonDropdown = ref<HTMLElement>();
const isOpening = computed(() => open.value);

useFlip(buttonDropdown, content);
useOutsideClick(content, () => clickAway(), {
  detectIframe: true,
  contains: [buttonDropdown],
  containSelectors: props.ignoreClickOutsideSelectors,
});

const setOpen = () => {
  if (timeout.value) clearTimeout(timeout.value);
  if (open.value) return;
  if (props.trigger === 'hover') open.value = true;
};

const closeHover = () => {
  if (timeout.value) clearTimeout(timeout.value);
  if (props.disable) return;
  if (!open.value) return;
  if (props.trigger === 'hover') {
    timeout.value = setTimeout(() => {
      open.value = false;
    }, 100);
  }
};

const clickAway = () => {
  emit('outside-click');
  if (open.value && props.trigger.includes('click')) {
    open.value = !open.value;
  } else open.value = false;
};

const clickOpen = () => {
  if (props.disable) return;
  if (props.trigger === 'click') {
    open.value = !open.value;
    emit('open');
  }
};

const close = () => {
  if (timeout.value) clearTimeout(timeout.value);
  open.value = false;
  emit('close');
};

// Watch props is open change
watch(
  () => props.isOpen,
  (newVal, oldVal) => {
    if (newVal != oldVal) {
      open.value = newVal;
    }
  },
);

useOutsideClick(content, clickAway, {
  detectIframe: true,
  contains: [buttonDropdown],
  containSelectors: props.ignoreClickOutsideSelectors,
});
</script>
<template>
  <div
    ref="dropdown"
    class="relative w-fit rounded-xl"
    :class="classWrapper"
    @mouseover="setOpen"
    @mouseleave="closeHover">
    <button ref="buttonDropdown" :class="classButton" class="relative h-full w-fit cursor-pointer" @click="clickOpen">
      <slot v-bind="{ isOpening, close }"></slot>
    </button>
    <div
      v-if="isOpening"
      ref="content"
      class="rounded-12 !bg-dark-300 shadow-dark-device absolute z-50 flex-col items-start"
      :class="[{ '!left-0': placement === 'left', '!right-0 !left-auto': placement === 'right' }, classPopover]">
      <slot ref="slotContent" name="content" v-bind="{ isOpening, close }"></slot>
    </div>
  </div>
</template>
